import {Component, OnInit} from '@angular/core';
import {AdminService} from '../admin.service';
import {Router} from '@angular/router';
import {UserDef} from './UserDef';
import _ from 'lodash';
import {ApiService} from '../../_services';

@Component({
  templateUrl: 'user.admin.component.html',
  styleUrls: ['../admin.component.scss']
})
export class UserAdminComponent implements OnInit {
  PERMISSION_LEVEL = {
    ROOT: 'root',
    SECTOR: 'sector',
    ISSUER: 'issuer'
  };
  PERMISSION_SET = {
    AUTHOR_CREATE: 'authorCreate',
    AUTHOR_EDIT: 'authorEdit',
    AUTHOR_VIEW: 'authorView',
    AUTHOR_APPROVE: 'authorApprove',
    EDIT_EDIT: 'editEdit',
    EDIT_VIEW: 'editView',
    EDIT_APPROVE: 'editApprove',
    COMPLIANCE_EDIT: 'sAEdit',
    COMPLIANCE_VIEW: 'sAView',
    COMPLIANCE_APPROVE: 'sAApprove',
    PRODUCTION_EDIT: 'productionEdit',
    PRODUCTION_VIEW: 'productionView',
    PRODUCTION_APPROVE: 'productionApprove'
  };
  private gridApi;
  private gridColumnApi;
  rootPermission: any;
  searchPermission = '';
  createView = false;
  selectedRow: any;
  associatesList = [];
  userDef: any;
  issuerDef = [];
  paginationPageSize = 20;
  rowSelection = 'single';
  selectedTab = 'general';
  gridStyle = {
    height: '480px'
  };
  columnDefs = [
    {
      headerName: 'Display Name',
      field: 'displayName',
      width: 260,
      cellClass: 'cell-wrap-text',
      autoHeight: true,
      sortable: true
    },
    {
      headerName: 'Phone number',
      field: 'phone',
      width: 220,
      cellClass: 'cell-wrap-text',
      autoHeight: true,
      sortable: true
    },
    {
      headerName: 'Job title',
      field: 'title',
      width: 240,
      cellClass: 'cell-wrap-text',
      autoHeight: true,
      sortable: true
    },
    {
      headerName: 'Email',
      field: 'email',
      width: 380,
      cellClass: 'cell-wrap-text',
      autoHeight: true,
      sortable: true
    }];

  constructor(
    private adminService: AdminService,
    private router: Router,
    private apiService: ApiService
  ) {

  }

  onPageSizeChanged($event) {
    this.gridApi.paginationSetPageSize(Number($event.target.value));
  }

  onSelectionChanged() {
    this.selectedRow = _.cloneDeep(this.gridApi.getSelectedRows()[0]);
    this.fetchUserPermissions();
  }

  onToggleExpand(sector: any) {
    sector.expand = !sector.expand;
    sector.toggleIcon = sector.toggleIcon === 'fa-plus-circle' ? 'fa-minus-circle' : 'fa-plus-circle';
  }

  onQuickFilterChanged($event) {
    this.gridApi.setQuickFilter($event.target.value);
  }

  /**
   * This method will handle changes made to permissions
   * @param permissionObject - Permission object which is changed
   * @param permission - The permission which is changed
   * @param level - Level of change of permission (root, sector or issuer)
   * @param parentSector - Needed in case when issuer permission is changed, so as to know the parent sector
   */
  onPermissionChange(permissionObject, permission, level, parentSector) {
    const {permissionSet} = permissionObject;
    permissionSet[permission] = !permissionSet[permission];
    if (level === this.PERMISSION_LEVEL.ROOT) {
      const {sectorPermission} = this.rootPermission;
      sectorPermission.forEach(sector => {
        sector.permissionSet[permission] = permissionSet[permission];
        const {issuerPermission} = sector;
        if (issuerPermission) {
          issuerPermission.forEach(issuer => issuer.permissionSet[permission] = permissionSet[permission]);
        }
      });
    } else if (level === this.PERMISSION_LEVEL.SECTOR) {
      const {issuerPermission} = permissionObject;
      // if (issuerPermission) {
      //   issuerPermission.forEach(issuer => issuer.permissionSet[permission] = permissionSet[permission]);
      // }
      // this.rootPermission.permissionSet[permission] = this.rootPermission.sectorPermission
      //   .every(sector => !!sector.permissionSet[permission]);

    } else {
      // parentSector.permissionSet[permission] = parentSector.issuerPermission.every(issuer => !!issuer.permissionSet[permission]);
      // this.rootPermission.permissionSet[permission] = this.rootPermission.sectorPermission
      //   .every(sector => !!sector.permissionSet[permission]);
    }
  }

  saveOnTabChange(tab) {
    if (this.selectedTab == 'general') {
      this.onSaveUserDetails();
    } else {
      this.onUpdateUserPermission();
    }
    this.selectedTab = tab;
  }

  onSaveUserDetails() {
    if (Array.isArray(this.selectedRow.associates)) {
      this.selectedRow.associates.forEach((associate, index) => {
        associate.sequence = index + 1;
      });
    }
    if (Array.isArray(this.selectedRow.coveredCompanies)) {
      this.selectedRow.coveredCompanies.forEach((coveredCompany, index) => {
        coveredCompany.sequence = index + 1;
      });
    }

    // console.log(this.selectedRow);
    this.adminService.saveUserGeneralDetails(this.selectedRow).subscribe((data: any) => {
      if (this.createView) {
        this.selectedRow.userID = data.userID;
        this.fetchUserPermissions();
      } else {
        const rowNode = this.gridApi.getRowNode(data.userID);
        rowNode.setData(this.selectedRow);
      }
    });
  }

  onPublishUserDetails() {
    this.adminService.publishUserGeneralDetails(this.selectedRow.userID).subscribe(data => {});
  }

  onUpdateUserPermission() {
    const sectorPermission = this.rootPermission.sectorPermission.map(sector => ({...sector}));
    this.rootPermission.sectorPermission = this.rootPermission.sectorPermission.map(sector => {
      delete sector.expand;
      delete sector.toggleIcon;
      return sector;
    });
    this.adminService
      .updateUserPermission(this.rootPermission, this.selectedRow.userID, this.selectedRow.loginName)
      .subscribe(() => {
        this.rootPermission.sectorPermission = sectorPermission;
      });
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

  }

  setTableHeight() {
    this.gridApi.sizeColumnsToFit();

    let availableHeight;
    let hotLength = 0;
    availableHeight = window.innerHeight - 230;
    /*Get the HOT length*/
    const hotData = this.userDef;
    if (hotData.length > 0) {
      hotLength = hotData.length > this.paginationPageSize ? this.paginationPageSize : hotData.length;
    }
    const newHeight = ((hotLength) * 28) + 100;
    if (newHeight < availableHeight) {
      availableHeight = newHeight;
    }

    this.gridStyle.height = `${availableHeight}px`;
  }

  getRowNodeId({userID}) {
    return userID;
  }

  fetchUserPermissions() {
    this.adminService
      .getUserPermissions(this.selectedRow.userID)
      .subscribe((permissions: any) => {
        this.rootPermission = permissions.rootPermission;
        this.rootPermission.sectorPermission = this.rootPermission.sectorPermission.map(sector => {
            sector.expand = true;
            sector.toggleIcon = 'fa-minus-circle';
            return sector;
          }
        );
      });
  }

  ngOnInit() {
    this.createView = this.router.url === '/users/newUser';
    if (this.createView) {
      this.selectedRow = new UserDef();
    }
    this.apiService.getAdminData('user').subscribe((data: any) => {
      if (data.hasOwnProperty('admin')) {
        this.userDef = data.admin.userDef.map(user => user);
        this.associatesList = data.admin.userDef.map(user => {
          return {
            userId: user.userID,
            displayName: user.displayName
          };
        });
        this.issuerDef = data.admin.issuerDef.map(issuer => {
          return {
            issuerID: issuer.issuerID,
            issuerName: issuer.issuerName
          };
        });
        
      }
      if (!this.createView) {
        this.setTableHeight();
      }
    });
  }
}
