import {Component, OnInit} from '@angular/core';
import {AdminService} from '../admin.service';
import * as moment from 'moment';
import {Router} from '@angular/router';
import {IssuerDef, TickerDef} from './IssuerDef';
import _ from 'lodash';
import {ApiService} from '../../_services';

@Component({
  templateUrl: 'issuer.admin.component.html',
  styleUrls: ['../admin.component.scss']
})
export class IssuerAdminComponent implements OnInit {
  private gridApi;
  private gridColumnApi;
  ISSUER_TAB = {GENERAL: 'GENERAL', SECURITY: 'SECURITY', DISCLOSURES: 'DISCLOSURES'};
  selectedTab: String;
  symbolSet = [];
  createView = false;
  countryNames = [];
  issuerDef: any;
  sectorDef = [];
  userDef = [];
  countryCodeChoice = [];
  exchangeChoice = [];
  paginationPageSize = 20;
  rowSelection = 'single';
  selectedRow: any;
  tipMessage = 'Entry required for one of these fields: CUSIP or ISIN';
  monthsList = moment.months();
  gridStyle = {
    height: '480px'
  };
  columnDefs = [
    {
      headerName: 'Issuer Name',
      field: 'issuerName',
      width: 260,
      cellClass: 'cell-wrap-text',
      autoHeight: true,
      sortable: true
    },
    {
      headerName: 'Price Chart Identifier',
      field: 'priceChartIdentifier',
      width: 200,
      cellClass: 'cell-wrap-text',
      autoHeight: true,
      sortable: true
    },
    {
      headerName: 'Primary Sector',
      field: 'primarySector',
      width: 300,
      cellClass: 'cell-wrap-text',
      autoHeight: true,
      sortable: true
    },
    {
      headerName: 'Country',
      field: 'countryCode',
      width: 340,
      cellClass: 'cell-wrap-text',
      autoHeight: true,
      sortable: true
    }];

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  setTableHeight() {
    this.gridApi.sizeColumnsToFit();

    let availableHeight;
    let hotLength = 0;
    availableHeight = window.innerHeight - 230;
    /*Get the HOT length*/
    const hotData = this.issuerDef || [];
    if (hotData.length > 0) {
      hotLength = hotData.length > this.paginationPageSize ? this.paginationPageSize : hotData.length;
    }
    const newHeight = ((hotLength) * 28) + 100;
    if (newHeight < availableHeight) {
      availableHeight = newHeight;
    }

    this.gridStyle.height = `${availableHeight}px`;
  }

  getRowNodeId({issuerID}) {
    return issuerID;
  }

  onQuickFilterChanged($event) {
    this.gridApi.setQuickFilter($event.target.value);
  }

  onPrimaryChange(ticker) {
    this.selectedRow.tickerDef.forEach(tkr => {
      tkr.primary = tkr.tickerID === ticker.tickerID;
    });
  }

  onSelectionChanged() {
    let tickerDef;
    this.selectedRow = _.cloneDeep(this.gridApi.getSelectedRows()[0]);
    if (!_.isArray(this.selectedRow.tickerDef)) {
      this.selectedRow.tickerDef = [];
    }
    while (this.selectedRow.tickerDef.length < 3) {
      tickerDef = new TickerDef();
      tickerDef.tickerID = this.selectedRow.tickerDef.length;
      this.selectedRow.tickerDef.push(tickerDef);
    }
  }

  onPageSizeChanged($event) {
    this.gridApi.paginationSetPageSize(Number($event.target.value));
  }

  onSaveIssuer(tab) {
    const selectedRowClone = _.cloneDeep(this.selectedRow);

    if (_.isArray(selectedRowClone.tickerDef)) {
      selectedRowClone.tickerDef = selectedRowClone.tickerDef.filter(ticker => ticker.tickerName);
    }

    if (Array.isArray(selectedRowClone.coverageTeam)) {
      selectedRowClone.coverageTeam.forEach((member, index) => {
        member.sequence = index + 1;
      });
    }

    switch (tab) {
      case this.ISSUER_TAB.GENERAL:
        this.adminService.saveIssuerGeneral(selectedRowClone).subscribe((data: any) => {
          if (this.createView) {
            this.selectedRow.issuerID = data.issuerId;
          } else {
            const rowNode = this.gridApi.getRowNode(data.issuerId);
            rowNode.setData(this.selectedRow);
          }
        });
        break;
      case this.ISSUER_TAB.SECURITY:
        this.adminService.saveIssuerSecurity(selectedRowClone).subscribe(data => {
          const rowNode = this.gridApi.getRowNode(this.selectedRow.issuerID);
          rowNode.setData(this.selectedRow);
        });
        break;
      case this.ISSUER_TAB.DISCLOSURES:
        this.adminService.saveIssuerDisclosures(selectedRowClone).subscribe(data => {
          const rowNode = this.gridApi.getRowNode(this.selectedRow.issuerID);
          rowNode.setData(this.selectedRow);
        });
        break;
      default:
        break;
    }
  }

  onPublishIssuer() {
    this.adminService.publishIssuer(this.selectedRow.issuerID).subscribe(data => {});
  }

  constructor(
    private adminService: AdminService,
    private router: Router,
    private apiService: ApiService
  ) {
    this.selectedTab = this.ISSUER_TAB.GENERAL;
  }

  isSaveDisabled() {
    return !(this.selectedRow.cusip || this.selectedRow.iSIN);
  }

  saveOnTabChange($event) {
    this.onSaveIssuer(this.selectedTab);
    this.selectedTab = $event;
  }

  ngOnInit() {
    this.createView = this.router.url === '/issuers/newIssuer';
    if (this.createView) {
      this.selectedRow = new IssuerDef();
      this.selectedRow.tickerDef = this.selectedRow.tickerDef.map((ticker, index) => {
        ticker.tickerID = index;
        return ticker;
      });
    }
    this.apiService.getAdminData('issuer').subscribe((data: any) => {
      if (data.hasOwnProperty('admin')) {
        this.issuerDef = data.admin.issuerDef.map(issuer => issuer);
        this.sectorDef = data.admin.sectorDef.map(sector => sector.sectorName);
        this.userDef = data.admin.userDef.map(user => {
          return {displayName: user.displayName, userID: user.userID};
        });
        this.countryCodeChoice = data.admin.countryCodeChoice.map(country => country);
        this.exchangeChoice = data.admin.exchangeChoice.map(exchange => exchange);
        this.symbolSet = data.admin.symbolSet.map(symbol => symbol);
        this.countryNames = data.admin.countryNames.map(country => country);
        
      }
      if (!this.createView) {
        this.setTableHeight();
      }
    });
  }

}
