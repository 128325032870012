import { Component, OnInit } from "@angular/core";
import { AlertService, ApiService, AuthenticationService, DataService } from "../_services";
import { DocumentModel } from "./document.model";
import { ActivatedRoute, Router } from "@angular/router";
import { companyColumnDefs, fixedEarningSummaryColumns } from "./commonData";
import { CheckboxRendererComponent, CustomHeaderRendererComponent, CustomUnitRendererComponent } from "../renderers";
import { getRequestDataModel } from "./requestDataModel";
import { saveAs } from "file-saver/dist/FileSaver";
import { BehaviorSubject } from "rxjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Helper } from "../_utils/helper";
import { HttpHeaders } from "@angular/common/http";

@Component({
  selector: "app-select-bindings",
  templateUrl: "./companyreport.component.html",
  styleUrls: ["./document.component.scss"],
})
export class CompanyreportComponent implements OnInit {
  formModel: DocumentModel;
  formEnabled: boolean;
  params: any;
  private companyGridApi: any;
  private companyGridColumnApi: any;
  private earningGridApi: any;
  private earningGridColumnApi: any;
  frameworkComponents: any;
  gridOptions: any;
  originalMatrix: any;
  selectedTab: String;
  selectedFinTab: String;

  isResetEnabled: boolean;
  resetMatrixValue = new BehaviorSubject(false);

  companyColumnDefs: any;
  companyRowData: any;
  earningColumnDefs: any;
  earningDynamicColums: any;
  earningRowData: any;

  yearArray: any;
  selectedFirstYear: number;
  selectedLastYear: number;

  comments: string;
  loggedInUserName: string;

  checkInDocument: any;
  checkInDocumentPath: string;
  previewHTML: any;

  companyGridStyle = {
    height: "400px",
  };
  earningGridStyle = {
    height: "400px",
  };
  isCharWarning: boolean = false;

  constructor(
    private authService: AuthenticationService,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private dataService: DataService,
    private router: Router,
    private alertService: AlertService,
    private modalService: NgbModal
  ) {
    const self = this;
    this.formModel = new DocumentModel();
    this.formEnabled = false;
    this.route.params.subscribe((params) => (this.params = params));
    this.companyColumnDefs = companyColumnDefs;
    this.earningColumnDefs = fixedEarningSummaryColumns;
    this.frameworkComponents = {
      checkBoxRenderer: CheckboxRendererComponent,
      agColumnHeader: CustomHeaderRendererComponent,
      unitRenderer: CustomUnitRendererComponent,
    };
    this.isResetEnabled = false;
    this.checkInDocumentPath = "Choose file";
    this.gridOptions = {
      onCellValueChanged: function () {
        self.isResetEnabled = true;
      },
    };
    this.selectedTab = "Metadata";
    this.selectedFinTab = "companyStatistics";

    this.resetMatrixValue.subscribe((value) => (this.isResetEnabled = value));
  }

  createDocument(event): void {
    const requestBody = {
      productID: this.formModel.actionNote.productID,
      versionID: 1,
      issuerName: event,
      stageName: "",
    };
    this.apiService.createSpecifiedDocument("companyreport", requestBody).subscribe((data) => {
      this.setInitialData(data);
    });
  }

  saveOnTabChange(tab) {
    if (!this.formModel.actionNote.readOnly) {
      switch (this.selectedTab) {
        case "Metadata":
          if (!this.formModel.priceValidator('targetPrice')) {
            this.alertService.error('Please check target price');
            break;
          }
          this.onSave();
          break;
        case "FinancialMatrix":
          if (this.selectedFinTab == "companyStatistics") {
            this.saveCompanyStatistics("update");
            this.selectedFinTab = "companyStatistics";
          } else {
            this.saveEarningSummary("update");
            this.selectedFinTab = "companyStatistics";
          }
          break;
        case "Comments":
          this.onAddComment();
          break;
        case "AuthorDocument":
          break;
        case "DistributionRecipients":
          this.saveDistribution();
          break;
        default:
          break;
      }
    }

    this.selectedTab = tab;
  }

  saveOnFinTabChange(tab) {
    if (!this.formModel.actionNote.readOnly) {
      if (this.selectedFinTab == "companyStatistics") {
        this.saveCompanyStatistics("update");
      } else {
        this.saveEarningSummary("update");
      }
    }

    this.selectedFinTab = tab;
  }

  onSave() {
    if (this.formModel.priceValidator('targetPrice')) {
      this.apiService.updateDocument("companyreport", getRequestDataModel(this.formModel, true)).subscribe((data) => { });
    }
    else{
      this.alertService.error('Please check target price');
    }
    
  }

  onClose() {
    this.apiService
      .closeDocument({
        productType: this.formModel.actionNote.productName,
        versionID: 1,
        stageName: this.dataService.selectedStage,
        productID: this.formModel.actionNote.productID,
      })
      .subscribe((data) => this.router.navigate([`/documents/${this.dataService.selectedStage.replace(/\s+/g, "").toLowerCase()}`]));
  }

  getComment(): string {
    const timeStamp = new Date();
    const previousComments = this.formModel.actionNote.comments || "";
    const currentComments = this.comments ? `<br /><br />${this.loggedInUserName} ${timeStamp.toString()}:<br />${this.comments}` : "";
    const comment = `${previousComments} ${currentComments}`;
    this.formModel.actionNote.comments = comment;
    return comment;
  }

  onAddComment() {
    this.apiService
      .saveDocumentComments("companyreport", {
        productID: this.formModel.actionNote.productID,
        VersionID: 1,
        Comments: this.getComment(),
      })
      .subscribe((data) => (this.comments = ""));
  }

  setInitialData(data) {
    if (data.actionNote.primaryIssuer.recommendationAction == '' || data.actionNote.primaryIssuer.recommendationAction == undefined) {
        data.actionNote.primaryIssuer.recommendationAction = null;
    }
    if (data.actionNote.primaryIssuer.targetPriceAction == '' || data.actionNote.primaryIssuer.targetPriceAction == undefined) {
        data.actionNote.primaryIssuer.targetPriceAction = null;
    }
    if (data.actionNote.primaryIssuer.currency == '' || data.actionNote.primaryIssuer.currency == undefined) {
      data.actionNote.primaryIssuer.currency = null;
    }
    if (data.actionNote.primaryIssuer.sectorRecommendation == '' || data.actionNote.primaryIssuer.sectorRecommendation == undefined) {
      data.actionNote.primaryIssuer.sectorRecommendation = null;
    }
    this.formModel.actionNote = data.actionNote;
    this.originalMatrix = JSON.parse(JSON.stringify(this.formModel.actionNote.primaryIssuer.matrix));
    this.setFinancialMatrix(this.formModel.actionNote.primaryIssuer.matrix);
    this.formModel.selectedPrimaryIssuer = this.formModel.actionNote.primaryIssuer.issuer.name;
    this.formEnabled = true;
    this.formModel.actionNote.distributionRecipient.forEach((value) => (value.prevSelected = value.selected));
    this.getFilteredSecondaryIssuers();
  }

  setFinancialMatrix(data) {
    this.companyRowData = data.snapshotParameterGroup.snapshotParameter;
    this.earningRowData = data.timeParameterGroup.timeParameter;
    this.earningDynamicColums = data.timeParameterGroup.timeParameterYear;
    this.selectedFirstYear = this.selectedLastYear = null;
    this.yearArray = [];
    this.earningDynamicColums.forEach((value) => {
      this.yearArray.push(value.year);
      if (value.selected && !this.selectedFirstYear) {
        this.selectedFirstYear = value.year;
      } else if (value.selected) {
        this.selectedLastYear = value.year;
      }
    });
  }

  ngOnInit(): void {
    this.authService.user.subscribe((user) => (this.loggedInUserName = user.displayName));
    if (this.params.action === "create") {
      this.apiService.createDocument({ productType: "Company Report" }).subscribe((data) => (this.formModel.actionNote.productID = Number(data)));
    } else {
      if (!this.dataService.selectedStage || !this.dataService.openDocumentData) {
        this.router.navigate(["/documents"]);
      } else {
        const requestBody = {
          productType: this.dataService.openDocumentData.productName,
          versionID: 1,
          stageName: this.dataService.selectedStage,
          productID: this.dataService.openDocumentData.productID,
        };
        this.apiService.openDocument(requestBody).subscribe((data) => {
          this.setInitialData(data);
        });
      }
    }
    this.apiService.getPrimaryIssuers().subscribe((data) => (this.formModel.primaryIssuers = data));
    this.apiService.getAnalysts().subscribe((data) => (this.formModel.primaryAuthors = data));
    this.apiService.getSecondaryIssuers().subscribe((data) => {
      this.formModel.secondaryIssuers = data;
      this.getFilteredSecondaryIssuers();
    });
  }

  getFilteredSecondaryIssuers() {
    this.formModel.filteredSecondaryIssuers =
      this.formModel.secondaryIssuers &&
      this.formModel.selectedPrimaryIssuer &&
      this.formModel.secondaryIssuers.filter((issuer) => !(issuer.name === this.formModel.selectedPrimaryIssuer));
  }

  changeYear(): void {
    this.earningGridApi.setColumnDefs(this.earningColumnDefs.concat(this.getDynamicTable()));
    this.earningGridApi.sizeColumnsToFit();
    this.isResetEnabled = true;
  }

  onCompanyGridReady(params) {
    this.isResetEnabled = false;
    this.companyGridApi = params.api;
    this.companyGridColumnApi = params.columnApi;
    this.companyGridApi.reset = this.resetMatrixValue;
    params.api.sizeColumnsToFit();
    let availableHeight;
    let hotLength = 0;
    availableHeight = window.innerHeight - 320;
    /*Get the HOT length*/
    const hotData = this.companyRowData;
    if (hotData.length > 0) {
      hotLength = hotData.length;
    }
    /*Calculate the new height for HOT
     * Here, 31 is the height of each row and
     * 48 is the padding left in the bottom
     */
    const newHeight = hotLength * 28 + 150;
    if (newHeight < availableHeight) {
      availableHeight = newHeight;
    }

    this.companyGridStyle.height = `${availableHeight}px`;
  }

  onEarningGridReady(params) {
    this.isResetEnabled = false;
    this.earningGridApi = params.api;
    this.earningGridColumnApi = params.columnApi;
    this.earningGridApi.setColumnDefs(this.earningColumnDefs.concat(this.getDynamicTable()));
    this.earningGridApi.reset = this.resetMatrixValue;
    params.api.sizeColumnsToFit();
    let availableHeight;
    let hotLength = 0;
    availableHeight = window.innerHeight - 375;
    /*Get the HOT length*/
    const hotData = this.earningRowData;
    if (hotData.length > 0) {
      hotLength = hotData.length;
    }
    /*Calculate the new height for HOT
     * Here, 31 is the height of each row and
     * 48 is the padding left in the bottom
     */
    const newHeight = hotLength * 28 + 150;
    if (newHeight < availableHeight) {
      availableHeight = newHeight;
    }

    this.earningGridStyle.height = `${availableHeight}px`;
  }

  getYearArray(event): void {
    return this.yearArray.filter((value) => (event === "firstYear" && value <= this.selectedLastYear) || (event === "lastYear" && value >= this.selectedFirstYear));
  }

  getDynamicTable(): any {
    /*Set dynamic clumn headers*/
    const columnHeaders = [];
    this.earningDynamicColums.forEach((value) => {
      columnHeaders.push({
        headerName: value.year,
        children: [
          {
            headerName: "Q1",
            minWidth: 75,
            hide: value.year < this.selectedFirstYear || value.year > this.selectedLastYear,
            headerComponentParams: {
              __metadata__: value,
              showSelect: true,
              selectValues: ["", "E", "A"],
            },
            valueGetter: function (params) {
              return params.data.timeParameterValue.find((data) => data.year === value.year).q1Value;
            },
            valueSetter: function (params) {
              params.data.timeParameterValue.forEach((data) => {
                if (data.year === value.year) {
                  data.q1Value = params.newValue;
                  params.api.reset.next(true);
                }
              });
            },
            editable: true,
            cellEditor: "agTextCellEditor",
          },
          {
            headerName: "Q2",
            minWidth: 75,
            hide: value.year < this.selectedFirstYear || value.year > this.selectedLastYear,
            headerComponentParams: {
              __metadata__: value,
              showSelect: true,
              selectValues: ["", "E", "A"],
            },
            valueGetter: function (params) {
              return params.data.timeParameterValue.find((data) => data.year === value.year).q2Value;
            },
            valueSetter: function (params) {
              params.data.timeParameterValue.forEach((data) => {
                if (data.year === value.year) {
                  data.q2Value = params.newValue;
                  params.api.reset.next(true);
                }
              });
            },
            editable: true,
            cellEditor: "agTextCellEditor",
          },
          {
            headerName: "Q3",
            minWidth: 75,
            hide: value.year < this.selectedFirstYear || value.year > this.selectedLastYear,
            headerComponentParams: {
              __metadata__: value,
              showSelect: true,
              selectValues: ["", "E", "A"],
            },
            valueGetter: function (params) {
              return params.data.timeParameterValue.find((data) => data.year === value.year).q3Value;
            },
            valueSetter: function (params) {
              params.data.timeParameterValue.forEach((data) => {
                if (data.year === value.year) {
                  data.q3Value = params.newValue;
                  params.api.reset.next(true);
                }
              });
            },
            editable: true,
            cellEditor: "agTextCellEditor",
          },
          {
            headerName: "Q4",
            minWidth: 75,
            hide: value.year < this.selectedFirstYear || value.year > this.selectedLastYear,
            headerComponentParams: {
              __metadata__: value,
              showSelect: true,
              selectValues: ["", "E", "A"],
            },
            valueGetter: function (params) {
              return params.data.timeParameterValue.find((data) => data.year === value.year).q4Value;
            },
            valueSetter: function (params) {
              params.data.timeParameterValue.forEach((data) => {
                if (data.year === value.year) {
                  data.q4Value = params.newValue;
                  params.api.reset.next(true);
                }
              });
            },
            editable: true,
            cellEditor: "agTextCellEditor",
          },
          {
            headerName: "FY",
            minWidth: 75,
            hide: value.year < this.selectedFirstYear || value.year > this.selectedLastYear,
            headerComponentParams: {
              __metadata__: value,
              showSelect: true,
              showRadio: true,
              selectValues: ["", "E", "A"],
            },
            valueGetter: function (params) {
              return params.data.timeParameterValue.find((data) => data.year === value.year).fYValue;
            },
            valueSetter: function (params) {
              params.data.timeParameterValue.forEach((data) => {
                if (data.year === value.year) {
                  data.fYValue = params.newValue;
                  params.api.reset.next(true);
                }
              });
            },
            editable: true,
            cellEditor: "agTextCellEditor",
          },
          {
            headerName: "CY",
            minWidth: 75,
            hide: value.year < this.selectedFirstYear || value.year > this.selectedLastYear,
            headerComponentParams: {
              __metadata__: value,
              showSelect: true,
              showRadio: true,
              selectValues: ["", "E", "A"],
            },
            valueGetter: function (params) {
              return params.data.timeParameterValue.find((data) => data.year === value.year).cYValue;
            },
            valueSetter: function (params) {
              params.data.timeParameterValue.forEach((data) => {
                if (data.year === value.year) {
                  data.cYValue = params.newValue;
                  params.api.reset.next(true);
                }
              });
            },
            editable: true,
            cellEditor: "agTextCellEditor",
          },
        ],
      });
    });

    return columnHeaders;
  }

  resetMatrix(type): void {
    if (type === "company") {
      this.formModel.actionNote.primaryIssuer.matrix.snapshotParameterGroup = JSON.parse(JSON.stringify(this.originalMatrix.snapshotParameterGroup));
    } else if (type === "earning") {
      this.formModel.actionNote.primaryIssuer.matrix.timeParameterGroup = JSON.parse(JSON.stringify(this.originalMatrix.timeParameterGroup));
    }
    this.setFinancialMatrix(this.formModel.actionNote.primaryIssuer.matrix);
    if (type === "earning") {
      this.changeYear();
    }
  }

  saveCompanyStatistics(action): void {
    const requestBody = {
      productID: this.formModel.actionNote.productID,
      versionID: 1,
      snapshotParameterGroup: {
        name: "Company Statistics",
        snapshotParameter: this.companyRowData,
      },
    };

    this.apiService.updateCompanyStatistics(requestBody, action).subscribe((data) => {
      if (action === "clear") {
        this.formModel.actionNote.primaryIssuer.matrix.snapshotParameterGroup = data.snapshotParameterGroup;
        this.setFinancialMatrix(this.formModel.actionNote.primaryIssuer.matrix);
        this.isResetEnabled = true;
      } else {
        this.originalMatrix = JSON.parse(JSON.stringify(this.formModel.actionNote.primaryIssuer.matrix));
      }
    });
  }

  saveEarningSummary(action): void {
    let tempArr = [];
    const name = ["EPS", "PriorEps", "FFO", "PriorFFO"];
    let regex = /(\$?(0|[1-9]\d{0,2}(,?\d{3})?)(\.\d\d?)?|\(\$?(0|[1-9]\d{0,2}(,?\d{3})?)(\.\d+)?\))$/gm
    //  /(\$?(0|[1-9]\d{0,2}(,?\d{3})?)(\.\d\d?)?|\(\$?(0|[1-9]\d{0,2}(,?\d{3})?)(\.\d\d?)?\))$/gm
    tempArr = this.earningRowData.filter(i => name.includes(i.name));
    for (let index = 0; index < tempArr.length; index++) {
      const element = tempArr[index].timeParameterValue;
      for (let i = 0; i < element.length; i++) {
        const elem = element[i];
        if (elem.year >= this.selectedFirstYear && elem.year <= this.selectedLastYear) {
          if (!elem.q1Value.match(regex) && !Helper.isBlank(Helper.removeWhiteSpace(elem.q1Value))) {
            this.alertService.error('Invalid value  ' + "'" + elem.q1Value + "'" + ' for ' + tempArr[index].displayName + ' in Q1  ' + elem.year);
            return;
          }
          else if (!elem.q2Value.match(regex) && !Helper.isBlank(Helper.removeWhiteSpace(elem.q2Value))) {
            this.alertService.error('Invalid value  ' + "'" + elem.q2Value + "'" + ' for ' + tempArr[index].displayName + ' in Q2  ' + elem.year);
            return;
          }
          else if (!elem.q3Value.match(regex) && !Helper.isBlank(Helper.removeWhiteSpace(elem.q3Value))) {
            this.alertService.error('Invalid value  ' + "'" + elem.q3Value + "'" + ' for ' + tempArr[index].displayName + ' in Q3  ' + elem.year);
            return;
          }
          else if (!elem.q4Value.match(regex) && !Helper.isBlank(Helper.removeWhiteSpace(elem.q4Value))) {
            this.alertService.error('Invalid value  ' + "'" + elem.q4Value + "'" + ' for ' + tempArr[index].displayName + ' in Q4  ' + elem.year);
            return;
          }
          else if (!elem.fYValue.match(regex) && !Helper.isBlank(Helper.removeWhiteSpace(elem.fYValue))) {
            this.alertService.error('Invalid value  ' + "'" + elem.fYValue + "'" + ' for ' + tempArr[index].displayName + ' in FY  ' + elem.year);
            return;
          }
          else if (!elem.cYValue.match(regex) && !Helper.isBlank(Helper.removeWhiteSpace(elem.cYValue))) {
            this.alertService.error('Invalid value  ' + "'" + elem.cYValue + "'" + ' for ' + tempArr[index].displayName + ' in CY  ' + elem.year);
            return;
          }
        }
      }
    }
    const requestBody = {
      productID: this.formModel.actionNote.productID,
      versionID: 1,
      timeParameterGroup: {
        name: "Earnings Summary",
        firstSelectedYear: "",
        lastSelectedYear: "",
        timeParameterYear: this.earningDynamicColums.map((value) => {
          value.selected = value.year >= this.selectedFirstYear && value.year <= this.selectedLastYear;
          return value;
        }),
        timeParameter: this.earningRowData,
      },
    };

    this.apiService.updateCompanyEarning(requestBody, action).subscribe((data) => {
      if (action === "clear") {
        this.formModel.actionNote.primaryIssuer.matrix.timeParameterGroup = data.timeParameterGroup;
        this.setFinancialMatrix(this.formModel.actionNote.primaryIssuer.matrix);
        this.isResetEnabled = true;
      } else {
        this.originalMatrix = JSON.parse(JSON.stringify(this.formModel.actionNote.primaryIssuer.matrix));
      }
    });
  }

  getBlankTemplate(): void {
    this.apiService.downloadBlankTemplate(this.formModel.actionNote.productName, this.formModel.actionNote.docID).subscribe((response) => {
      let fileName = response.headers.get('content-disposition').split('filename="')[1].split(';')[0].replace('"','');
      const blob = new Blob([response.body], { type: "application/msword" });
      if(fileName){
        saveAs(blob, fileName);
      }
      else{
        saveAs(blob, `${this.formModel.actionNote.docID}.docx`);
      }
    });
  }

  checkOutDocument(): void {
    this.apiService.checkoutDocument(this.formModel.actionNote.productName, "workflow", this.formModel.actionNote.docID, this.dataService.selectedStage).subscribe((response) => {
      let fileName = response.headers.get('content-disposition').split('filename="')[1].split(';')[0].replace('"','');
      const blob = new Blob([response.body], { type: "application/msword" });
      if(fileName){
        saveAs(blob, fileName);
      }
      else{
        saveAs(blob, `${this.formModel.actionNote.docID}.docx`);
      }
    });
  }

  // onFileChange(event, fileInput): void {
  //   if (`${this.formModel.actionNote.docID}.docx` === fileInput.files[0].name || `${this.formModel.actionNote.docID}.doc` === fileInput.files[0].name) {
  //     this.checkInDocument = fileInput.files[0];
  //     this.checkInDocumentPath = fileInput.files[0].name;
  //   } else {
  //     this.alertService.error("Please upload a valid document");
  //   }
  //   fileInput.value = null;
  // }

  onFileChange(event, fileInput): void {
    const file = fileInput.files[0];
    const fileName = file.name;
    const fileExtension = fileName.split('.').pop().toLowerCase();
  
    const validExtensions = ['doc', 'docx'];
  
    if (validExtensions.includes(fileExtension)) {
      this.checkInDocument = file;
      this.checkInDocumentPath = fileName;
    } else {
      this.alertService.error("Please upload a valid document (.doc or .docx)");
    }
  
    fileInput.value = null;
  }
  

  changexlxs(event, file, content): void {
    this.modalService.open(content).result.then(
      (result) => {
        const formData = new FormData();
        formData.append("uploadDocument", file.files[0]);
        formData.append("actionFileName", file.files[0].name);
        formData.append("loginName", this.authService.user.value.loginName);
        this.apiService.uploadFinancialModel(formData).subscribe(
          (response) => {
            if(response.statusCode == 200){
              this.importFinancialMatrix();
            }
            file.value = null;
          },
          (error) => {
            file.value = null;
          }
        );
      },
      (reason) => {
        file.value = null;
      }
    );
  }

  checkIn(): void {
    const formData = new FormData();
    formData.append("uploadDocument", this.checkInDocument);
    formData.append("actionDestination", "workflow");
    formData.append("actionFileName", this.checkInDocumentPath);
    formData.append("productID", `${this.formModel.actionNote.productID}`);
    formData.append("loginName", this.authService.user.value.loginName);
    this.apiService.checkinDocument(formData).subscribe((response) => {
      this.checkInDocument = null;
      this.checkInDocumentPath = "Choose file";
    });
  }

  importFinancialMatrix(): void {
    const requestBody = {
      productID: this.formModel.actionNote.productID,
      stageName: this.dataService.selectedStage,
      issuerDef: {
        issuerID: this.formModel.actionNote.primaryIssuer.issuer.issuerID,
        issuerName: this.formModel.actionNote.primaryIssuer.issuer.name,
        sequence: "1",
      },
    };

    this.apiService.importFinancialMatrix(requestBody,  { headers: new HttpHeaders({ 'X-Skip-Loader': 'true' }) }).subscribe((response) => {
      this.formModel.actionNote.primaryIssuer.matrix = response.matrix;
      this.originalMatrix = JSON.parse(JSON.stringify(this.formModel.actionNote.primaryIssuer.matrix));
      this.setFinancialMatrix(this.formModel.actionNote.primaryIssuer.matrix);
    });
  }
  importPublishedMatrix(){
    const requestBody = {
      productID: this.formModel.actionNote.productID,
      stageName: this.dataService.selectedStage,
      issuerDef: {
        issuerID: this.formModel.actionNote.primaryIssuer.issuer.issuerID,
        issuerName: this.formModel.actionNote.primaryIssuer.issuer.name,
        sequence: "1",
      },
    };
    this.apiService.getPublishedMatrix(requestBody).subscribe((response) => {
      this.formModel.actionNote.primaryIssuer.matrix = response.matrix;
      this.originalMatrix = JSON.parse(JSON.stringify(this.formModel.actionNote.primaryIssuer.matrix));
      this.setFinancialMatrix(this.formModel.actionNote.primaryIssuer.matrix);
    });
  }

  enableTab(tab): boolean {
    return !(tab === "distribution" && this.dataService.selectedStage === "Production");
  }

  checkDistributionRecipients(data) {
    this.formModel.actionNote.distributionRecipient.forEach((value) => {
      if (value.name.includes("Limited") || value.name.includes("Unlimited")) {
        value.selected = false;
      }
      if (value.name.includes(data)) {
        value.selected = true;
      }
    });
  }

  checkDisabled(data) {
    if (data.name.includes("Limited") || data.name.includes("Unlimited")) {
      return !data.name.includes(this.formModel.actionNote.distributionGroup);
    } else {
      return !data.prevSelected;
    }
  }

  saveDistribution() {
    const requestBody = {
      versionID: 1,
      distributionGroup: this.formModel.actionNote.distributionGroup,
      productID: this.formModel.actionNote.productID,
      distributionRecipient: this.formModel.actionNote.distributionRecipient,
    };

    this.apiService.saveDistribution(requestBody).subscribe();
  }

  abstractHTMLChanged(event) {
    this.formModel.actionNote.metadata.abstract = event.text;
  }

  openAbstractHTML() {
    const requestBody = {
      tagName: null,
      text: this.formModel.actionNote.metadata.htmlText,
    };
    this.apiService.previewHTmlBody(requestBody).subscribe((response) => {
      const newWindow = window.open();
      newWindow.document.write(response.text);
      newWindow.document.close();
    });
  }
  limitChar(event){
    if(this.formModel.actionNote.primaryIssuer.companyDescription.length >= 1350){
        this.isCharWarning = true
    }
    else{
      this.isCharWarning = false
    }
  }
}
