
	  
	  
	  
	  
import {Component, OnInit} from '@angular/core';
import {AdminPanel} from '../common/AdminPanel';
import {panels as panelData} from '../common/panelData';
import {ApiService, AuthenticationService, DataService} from '../_services';
import {saveAs} from 'file-saver/dist/FileSaver';
import _ from 'lodash';

@Component({
  templateUrl: 'home.component.html',
  styleUrls: ['home.component.scss']
})
export class HomeComponent implements OnInit {
  adminPanels = [];
  isAdmin: Boolean;
  panels: any;

  constructor(private api: ApiService, private authService: AuthenticationService, private dataService: DataService) {
    const user = this.authService.user.getValue();
    this.isAdmin = user.sAPermission || user.pAPermission;
    this.panels = _.cloneDeep(panelData);
  }

  addHomePanel() {
    if (this.isAdmin) {
      this.panels.filter(panel => !['Ratings Distribution'].includes(panel.name)).forEach(panel => {
        this.adminPanels.push(
          new AdminPanel(
            panel.icon,
            panel.iconUrl,
            panel.name,
            panel.description,
            panel.options
          )
        );
      });
    } else {
      this.panels.filter(panel => ['New Documents', 'Other Documents', 'Add-in installer', 'filler','Industry Documents']
        .includes(panel.name)).forEach(panel => {
        if (panel.name === 'Other Documents') {
          _.find(panel.options, ['optionName', 'Morning Call']).enabled = false;
        }
        this.adminPanels.push(
          new AdminPanel(
            panel.icon,
            panel.iconUrl,
            panel.name,
            panel.description,
            panel.options
          )
        );
      });
    }
  }


  downloadInstaller(value) {
    // if (value !== 'Download') {
    //   return;
    // }
    console.log(value);
    if ((value === 'ExcelAddin') || (value === 'WordAddin')) {
      this.api.downloadAddInInstaller(value).subscribe(response => {
        const blob = new Blob([response.body], {type: 'application/zip'});
        let filename = 'installer.zip';
        const disposition = response.headers.get('Content-Disposition');
        if (disposition && disposition.indexOf('attachment') !== -1) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
          }
        }
        saveAs(blob, filename);
      });
    }
    else if ('BlankWorkbook' === value) {
      this.api.downloadBlankWorkbook().subscribe(response => {
        const blob = new Blob([response.body], {type: 'application/msexcel'});
        saveAs(blob, this.dataService.getFilename(response, 'BlankWorkBook.xlsx'));
      });
    }
    
    
  }

  ngOnInit() {
    this.addHomePanel();
  }
}










	  