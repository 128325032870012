import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {AuthenticationService} from '../_services';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {PasswordValidation} from '../_helpers';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss']
})
export class LoginComponent implements OnInit {
  loading = false;
  submitted = false;
  returnUrl: string;
  changePasswordForm: FormGroup;
  user = '';
  pass = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService, private modalService: NgbModal) {
    this.changePasswordForm = new FormGroup({
      username: new FormControl('', Validators.required),
      oldPassword: new FormControl('', Validators.required),
      newPassword: new FormControl('', Validators.required),
      confirmPassword: new FormControl('', Validators.required)
    }, {
      validators: PasswordValidation
    });
  }

  ngOnInit() {

    this.user = '';
    this.pass = '';

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }


  onSubmit(content) {
    this.submitted = true;

    this.loading = true;
    this.authenticationService.login(this.user, this.pass, content).subscribe(
      data => {
        const user = data.user;
        this.authenticationService.user.next(user);
        sessionStorage.setItem('currentUser', JSON.stringify(user));
        if (user.pAPermission && user.sAPermission) {
          this.router.navigate(['/home']);
        } else {
          this.router.navigate(['/documents']);
        }

      },
      error => {
        this.pass = '';
        this.authenticationService.user.next(null);
        this.changePasswordForm.setValue({
          username: this.user,
          oldPassword: '',
          newPassword: '',
          confirmPassword: ''
        });
        if (error.exceptionCode === '4') {
          this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'});
        }
      }
    );
  }

  change(): void {
    const requestBody = {
      loginName: this.user,
      userName: this.changePasswordForm.get('username').value,
      currentPassword: this.changePasswordForm.get('oldPassword').value,
      newPassword: this.changePasswordForm.get('newPassword').value,
      newPasswordAgain: this.changePasswordForm.get('confirmPassword').value
    };
    this.authenticationService.changePassword(requestBody).subscribe();
  }
}
