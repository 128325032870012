import {Component, OnInit} from '@angular/core';
import {AlertService, ApiService, AuthenticationService} from '../_services';
import _ from 'lodash';
import {saveAs} from 'file-saver/dist/FileSaver';
import {Router} from '@angular/router';
import * as moment from 'moment';

@Component({
  templateUrl: './morningcall.component.html',
  styleUrls: ['./all.documents.component.scss']
})

export class MorningcallComponent implements OnInit {
  private gridApi;
  private gridColumnApi;
  columnDefs = [];
  documentSynopsis = [];
  productTypedistribution: any;
  compendium: any;
  rowHeight = 30;
  paginationPageSize = 10;
  rowSelection = 'multiple';
  selectedDoc: any;
  selectedProducts = [];
  distributionGroups = ['Limited', 'Unlimited'];
  createNewCompendiumDocEnabled = false;
  checkInDocument: any;
  checkInDocumentName: string;
  checkInDocumentSelected = false;

  constructor(private apiService: ApiService, private authService: AuthenticationService,
              private router: Router, private alertService: AlertService) {
    this.columnDefs = [
      {
        headerName: 'DocumentID',
        field: 'docID',
        width: 130,
        cellClass: 'cell-wrap-text',
        autoHeight: false,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true
      },
      {
        headerName: 'Primary Issuer',
        field: 'primaryIssuer',
        width: 200,
        cellClass: 'cell-wrap-text',
        autoHeight: false
      },
      {
        headerName: 'Document Type',
        field: 'docTypeName',
        width: 130,
        cellClass: 'cell-wrap-text',
        autoHeight: false
      },
      {
        headerName: 'Published',
        field: 'publishDateTime',
        width: 150,
        cellClass: 'cell-wrap-text',
        autoHeight: false
      },
      {
        headerName: 'Primary Analyst',
        field: 'primaryAnalyst',
        width: 150,
        cellClass: 'cell-wrap-text',
        autoHeight: false
      },
      {
        headerName: 'Headline',
        field: 'headLine',
        width: 150,
        cellClass: 'cell-wrap-text',
        autoHeight: false
      }];
    this.checkInDocumentName = 'Choose file';
  }

  onGridReady(params) {
    let selectedNodes = [];
    if (this.gridApi && this.gridApi.getSelectedNodes().length) {
      selectedNodes = this.gridApi.getSelectedNodes().map(node => node.data.docID);
    }
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();

    // This is to persist the state of checkbox when navigating between tabs
    this.gridApi.forEachNode(node => node.setSelected(selectedNodes.includes(node.data.docID)));
  }

  ngOnInit() {
    this.apiService.getMorningCallDocuments().subscribe(data => {
      this.compendium = _.cloneDeep(data.compendium);
      this.documentSynopsis = this.compendium.documentSynopsis;
      this.productTypedistribution = this.compendium.productTypedistributions[0];
    });
  }

  onFileChange(event, fileInput): void {

    if (`MC_${moment().format('MMDDYYYY')}.docx` === event.target.files[0].name) {
      this.checkInDocument = event.target.files[0];
      this.checkInDocumentName = event.target.files[0].name;
      this.checkInDocumentSelected = true;
        if (!this.createNewCompendiumDocEnabled) {
          this.alertService.error('Please select document(s) from compendium.');
        }
    } else {
      this.alertService.error('Please upload a valid document');
    }
    fileInput.value = null;

  }

  onCheckIn(): void {
    const formData = new FormData();
    formData.append('uploadDocument', this.checkInDocument);
    formData.append('actionDestination', 'workflow');
    formData.append('documentID', this.checkInDocumentName.replace(/\.[^/.]+$/, ''));
    formData.append('actionFileName', this.checkInDocumentName);
    formData.append('products', JSON.stringify(this.selectedProducts));
    formData.append('distributions', JSON.stringify(this.productTypedistribution.distributionRecipient));
    formData.append('loginName', this.authService.user.value.loginName);
    this.apiService.checkinCompendiumDocument(formData).subscribe(response => {
      this.checkInDocument = null;
      this.checkInDocumentName = 'Choose file';
      this.checkInDocumentSelected = false;
      this.router.navigate(['/documents/recentlypublished']);
    });
  }

  onSelectionChanged() {
    this.createNewCompendiumDocEnabled = this.gridApi.getSelectedNodes().length > 0;
    this.selectedProducts = this.gridApi.getSelectedNodes().map(row => {
      return {productID: row.data.productID, versonID: row.data.versionID};
    });
  }

  onRowClicked({data}) {
    this.selectedDoc = _.cloneDeep(data);
  }

  onCreateNewCompendiumDocument() {
    const requestBody = _.cloneDeep(this.compendium);
    requestBody.documentSynopsis = this.gridApi.getSelectedRows().map(row => ({...row, ...{selected: true}}))
    requestBody.distributionRecipient = requestBody.productTypedistributions[0].distributionRecipient;
    delete requestBody['productTypedistributions'];
    this.apiService.createNewCompendiumDocument(requestBody).subscribe(document => {

      
      this.apiService.downloadNewCompendiumDocument(document.replace(/\.[^/.]+$/, '')).subscribe(response => {
        const blob = new Blob([response], {type: 'application/msword'});
        saveAs(blob, document);
      });

    });
  }

  onDistributionGroupChange() {
    this.productTypedistribution.distributionRecipient = this.productTypedistribution.distributionRecipient.map(recipient => {
      if (recipient.name.indexOf(this.distributionGroups[0]) !== -1 ||
        recipient.name.indexOf(this.distributionGroups[1]) !== -1) {
        recipient.editable = recipient.name.indexOf(this.productTypedistribution.distributionGroup) !== -1;
        recipient.selected = recipient.name.indexOf(this.productTypedistribution.distributionGroup) !== -1;
      }
      return recipient;
    });
  }
}
