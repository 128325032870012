import {AfterViewInit, Component, OnDestroy} from '@angular/core';
import {ApiService, AuthenticationService, DataService} from '../_services';
import {panels} from './commonData';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ActionRendererComponent, DocumentHeadlineRendererComponent, DocumentOpenRendererComponent} from '../renderers';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-documents',
  templateUrl: './all.documents.component.html',
  styleUrls: ['./all.documents.component.scss']
})
export class AllDocumentsComponent implements AfterViewInit, OnDestroy {
  documentList: any;
  gridStyle = {
    height: '480px'
  };
  columnDefs = [
    {
      headerName: 'State',
      field: 'locked',
      width: 60,
      cellClass: 'cell-wrap-text',
      autoHeight: false,
      cellRenderer: 'actionRenderer',
      sortable: true,
      headerCheckboxSelection: () => this.approveFlag,
      headerCheckboxSelectionFilteredOnly: () => true,
      checkboxSelection: () => this.approveFlag
    },
    {
      headerName: 'Document ID',
      field: 'docID',
      width: 150,
      cellClass: 'cell-wrap-text',
      autoHeight: false,
      cellRenderer: 'documentOpenRenderer',
      sortable: true
    },
    {
      headerName: 'Document Type',
      field: 'productName',
      width: 120,
      cellClass: 'cell-wrap-text',
      autoHeight: false,
      sortable: true
    },
    {
      headerName: 'Headline',
      field: 'headline',
      width: 140,
      cellClass: 'cell-wrap-text',
      autoHeight: false,
      cellRenderer: 'documentHeadlineRenderer',
      sortable: true
    },
    {
      headerName: 'Primary Analyst',
      field: 'primaryAnalyst',
      width: 140,
      cellClass: 'cell-wrap-text',
      autoHeight: false,
      cellRenderer: 'documentHeadlineRenderer',
      sortable: true
    },
    {
      headerName: 'Date Modified',
      field: 'modificationDateTime',
      width: 110,
      cellClass: 'cell-wrap-text',
      autoHeight: false,
      sortable: true
    },
    {
      headerName: 'Last Modified',
      field: 'lastModifiedBy',
      width: 100,
      cellClass: 'cell-wrap-text',
      autoHeight: false,
      sortable: true
    },
    {
      headerName: 'Status',
      field: 'status',
      width: 80,
      cellClass: 'cell-wrap-text',
      autoHeight: false,
      sortable: true
    },
    {
      headerName: 'Lock Owner',
      field: 'lockOwnerLoginName',
      width: 100,
      cellClass: 'cell-wrap-text',
      autoHeight: false,
      sortable: true
    },
    {
      headerName: 'View',
      field: 'make',
      width: 60,
      cellClass: 'cell-wrap-text',
      autoHeight: false,
      cellRenderer: 'actionRenderer',
      sortable: true
    },
    {
      headerName: 'Action',
      field: 'make',
      width: 70,
      cellClass: 'cell-wrap-text',
      autoHeight: false,
      cellRenderer: 'actionRenderer',
      sortable: true
    }
  ];

  rowHeight = 50;
  params: any;
  rowData: any;
  panelData: any;
  selectedTab: string;
  user: any;
  gridApi;
  gridColumnApi;
  frameworkComponents: any;
  paginationPageSize = 20;
  modalRef: any;
  approveFlag = false;

  constructor(private apiService: ApiService, public modalService: NgbModal, private dataService: DataService,
              private authService: AuthenticationService, private route: ActivatedRoute) {
    this.panelData = panels;
    this.user = this.authService.user.getValue();
    this.route.params.subscribe(params => (this.params = params));
    this.selectedTab = 'Authoring';
    this.panelData.forEach((value) => {
      value.options.forEach((option) => {
        option.enabled = !(option.optionName === 'Morning Call' && !(this.user.pAPermission || this.user.sAPermission));
      });
    });
    this.frameworkComponents = {
      documentHeadlineRenderer: DocumentHeadlineRendererComponent,
      documentOpenRenderer: DocumentOpenRendererComponent,
      actionRenderer: ActionRendererComponent
    };
  }

  getTabName(action): String {
    switch (action) {
      case 'recentlypublished':
        return 'Recently Published';
      case 'editing':
        return 'Editing';
      case 'compliance':
        return 'Compliance';
      case 'production':
        return 'Production';
      default:
        return 'Authoring';
    }
  }

  ngAfterViewInit() {
    this.loadTableData(this.getTabName(this.params.action) || 'Authoring');
  }

  isRowSelectable(rowNode) {
    return !rowNode.data.disable;
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.componentParent = this;
    params.api.sizeColumnsToFit();
    let availableHeight;
    let hotLength = 0;
    availableHeight = window.innerHeight - 230;
    /*Get the HOT length*/
    const hotData = this.rowData;
    if (hotData.length > 0) {
      hotLength = hotData.length;
    }
    /*Calculate the new height for HOT
     * Here, 31 is the height of each row and
     * 48 is the padding left in the bottom
     */
    const newHeight = ((hotLength) * this.rowHeight) + 150;
    if (newHeight < availableHeight) {
      availableHeight = newHeight;
    }

    this.gridStyle.height = `${availableHeight}px`;
  }

  loadTableData(stage) {
    this.apiService.getDocuments(stage).subscribe(data => {
      this.dataService.selectedStage = stage;
      data.workflow.stage.sort((a, b) => a.sequence - b.sequence);
      this.documentList = data.workflow.stage;
      const filteredData = this.documentList.find(value => value.name === stage);
      this.rowData = filteredData.workflowProduct || [];
      this.approveFlag = filteredData.approveFlag || false;
      this.selectedTab = stage;
    });

  }

  onQuickFilterChanged($event) {
    this.gridApi.setQuickFilter($event.target.value);
  }

  onPageSizeChanged($event) {
    this.gridApi.paginationSetPageSize(Number($event.target.value));
  }

  openCreateDocumentModal(content) {
    this.modalRef = this.modalService.open(content, {
      centered: true,
      size: 'xl'
    });
  }

  ngOnDestroy(): void {
    if (this.modalRef) {
      this.modalRef.dismiss();
    }
  }

  onActionCellClicked(action, rowData, distributions): void {
    if (action === 'close') {
      this.apiService
        .closeDocument({
          productType: rowData.productName,
          versionID: 1,
          stageName: this.dataService.selectedStage,
          productID: rowData.productID
        })
        .subscribe(response => {
          
          // response.workflow.stage.sort((a, b) => a.sequence - b.sequence);
          // this.documentList = response.workflow.stage;
          this.loadTableData(this.dataService.selectedStage);
        });
    } else {
      const requestBody = {
        versionID: 1,
        stageName: this.dataService.selectedStage,
        productID: rowData.productID,
        headline: rowData.headline,
        productType: rowData.productName,
        documentID: rowData.docID,
        distributionRecipient: distributions
      };
      this.apiService.actionDocument(requestBody, action).subscribe(response => {
        response.workflow.stage.sort((a, b) => a.sequence - b.sequence);
        this.documentList = response.workflow.stage;
        this.loadTableData(this.dataService.selectedStage);
      });
    }


  }

  publishAll() {
    const requestBody = {
      stageName: this.selectedTab,
      workflowList: this.gridApi.getSelectedNodes().map(node => node.data)
    };
    this.apiService.actionDocument(requestBody, 'allapprove').subscribe(() => this.loadTableData(this.selectedTab));
  }
}
